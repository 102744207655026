<template>
  <div class="content__container">
    <p class="promo promo_blocks2">{{ "s4p15_text_1" | localize }}</p>
    <form @submit.prevent="submitHandler">
      <div v-for="(acc, k) in accounts" :key="k">
        <div class="promo__container" v-if="clientCount > 1">
          <p class="promo">{{ "problem" | localize }} {{ k + 1 }}</p>
          <button type="button" v-if="accounts.length > 1" class="delete" @click="deleteAccount(k)"></button>
        </div>
        <div :class="'scroll_to_' + k"></div>

        <div class="form__group">
          <v-select
            v-model.lazy="acc.type"
            @blur="check(k, 'type')"
            :class="{ invalid: checks[k].type && !acc.type }"
            item-text="name"
            item-value="value"
            :items="getList('s4p15_select_1_value_', 4)"
            :label="'s4p15_label_1' | localize"
            :placeholder="'select' | localize"
            :menu-props="{ offsetY: true }"
            append-icon="icon-arrow-down"
            outlined
          ></v-select>

          <v-select
            v-if="members > 1"
            v-model.lazy="acc.client"
            @blur="check(k, 'client')"
            :class="{ invalid: checks[k].client && !acc.client }"
            :items="clients"
            item-text="name"
            item-value="value"
            :label="'s3p7_label_3' | localize"
            :placeholder="'select' | localize"
            :menu-props="{ offsetY: true }"
            append-icon="icon-arrow-down"
            outlined
          ></v-select>
        </div>

        <div class="form__group">
          <v-select
            v-if="acc.type == 1"
            v-model.lazy="acc.bill"
            @blur="check(k, 'bill')"
            :class="{ invalid: checks[k].bill && !acc.bill }"
            :items="[1, 2, 3, 4, 5, 6, 7, 8]"
            :label="'s4p15_label_2' | localize"
            :placeholder="'select' | localize"
            :menu-props="{ offsetY: true }"
            append-icon="icon-arrow-down"
            outlined
          ></v-select>
        </div>

        <div class="form__group">
          <v-select
            v-if="acc.type == 2"
            v-model.lazy="acc.loan"
            @blur="check(k, 'loan')"
            :class="{ invalid: checks[k].loan && !acc.loan }"
            item-text="name"
            item-value="value"
            :items="getList('month_ago_', 12)"
            :label="'s4p15_label_3' | localize"
            :placeholder="'select' | localize"
            :menu-props="{ offsetY: true }"
            append-icon="icon-arrow-down"
            outlined
          ></v-select>
        </div>

        <div class="form__group">
          <v-select
            v-if="acc.type == 3"
            v-model.lazy="acc.execution"
            @blur="check(k, 'execution')"
            :class="{ invalid: checks[k].execution && !acc.execution }"
            item-text="name"
            item-value="value"
            :items="getList('month_ago_', 12)"
            :label="'s4p15_label_4' | localize"
            :placeholder="'select' | localize"
            :menu-props="{ offsetY: true }"
            append-icon="icon-arrow-down"
            outlined
          ></v-select>
        </div>

        <div class="form__group">
          <v-select
            v-if="acc.type == 4"
            v-model.lazy="acc.limit"
            @blur="check(k, 'limit')"
            :class="{ invalid: checks[k].limit && !acc.limit }"
            item-text="name"
            item-value="value"
            :items="getList('month_ago_', 12)"
            :label="'s4p15_label_5' | localize"
            :placeholder="'select' | localize"
            :menu-props="{ offsetY: true }"
            append-icon="icon-arrow-down"
            outlined
          ></v-select>
        </div>
      </div>

      <div class="error__container">
        <p class="error" v-if="error">{{ error | localize }}</p>
      </div>

      <button type="button" class="button button__form" :class="{ button__form_disabled: !success }" @click="submitHandler">
          {{ "form_continue" | localize }}
      </button>

      <button type="button" class="button button__form button__form_empty" @click="addAccount(scheme)">
          + {{ "form_add_account" | localize }}
      </button>
    </form>
  </div>
</template>

<script>
import { clientMixin } from '@/mixins/clientMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'

export default {
  name: 'About_problems',
  mixins: [clientMixin, commonMixin, numberMixin],
  props: { 
    service: { type: String, required: true },
    nextlink: { type: String, required: true }
  },
  data: () => ({ scheme: { type: null, bill: null, loan: null, execution: null, limit: null } }),
  created () {
    const data = this.$cookies.get(this.service) || {}

    // Получатели и данные
    this.members = data.saved_clients ? data.saved_clients.length : 1
    this.clients = data.saved_clients || []
    this.accounts = data.problems || []
    if (this.members > 1) this.scheme.client = null

    this.prepareScheme(this.scheme)
    this.success = !this.checkError()
  },
  methods: {
    setDefaults () {
      for (const idx of this.accounts.keys()) {
        if (this.accounts[idx].type === 1) {
          this.accounts[idx].loan = this.accounts[idx].execution = this.accounts[idx].limit = 0
        }
        if (this.accounts[idx].type === 2) {
          this.accounts[idx].bill = this.accounts[idx].execution = this.accounts[idx].limit = 0
        }
        if (this.accounts[idx].type === 3) {
          this.accounts[idx].bill = this.accounts[idx].loan = this.accounts[idx].limit = 0
        }
        if (this.accounts[idx].type === 4) {
          this.accounts[idx].loan = this.accounts[idx].execution = this.accounts[idx].bill = 0
        }
      }
    },
    submitHandler () {
      this.setDefaults()
      const errorKey = this.checkError(true)
      if (errorKey !== false) {
        this.$scrollTo('.scroll_to_' + errorKey, 300, { offset: -150 })
        this.success = false
        return
      }

      this.success = true
      const data = this.$cookies.get(this.service) || {}
      data.problems = this.accounts
      this.$cookies.set(this.service, data)
      this.saveClientData('client_problems',  this.nextlink, this.service)
    }
  }
}
</script>