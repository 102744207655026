<template>
  <div class="page">
    <div class="wrapper illustration illustration_brackets">
      <Backlink title="questionnaire" service="refMortgage" backlink="/refinance-mortgage/problems" />
      <div class="content">
        <AboutProblems service="refMortgage" nextlink="/refinance-mortgage/reason" />
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import AboutProblems from '@/components/service/AboutProblems.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Refinance_mortgage_about_problems',
  components: { Backlink, AboutProblems }
}
</script>
